import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import Layout from '../components/layout'

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount, group: allTags } = data.allMarkdownRemark
  const featuredPostsTitle = "Top Posts"
  const tagsTitle = `${totalCount} #${tag} post${totalCount === 1 ? '' : 's'}`
  const tagHeader = tag === "featured" ? featuredPostsTitle : tagsTitle

  return (
    <Layout>
      <div className="content-box clearfix">
        <div className="blog-tags">
          <h1>{tagHeader}</h1>
          <ul className="tag-list">
            {edges.map(({ node }) => {
              const { title, date } = node.frontmatter
              const { slug } = node.fields
              const { timeToRead } = node

              return (
                <li key={slug}>
                  <div><Link to={slug}>{title}</Link></div>
                  <div class="post-metadata">{date} | {timeToRead} minute read</div>
                </li>
              )
            })}
          </ul>

          <div className="">
            <h2>All Tags</h2>
            <ul className="tags">
              {allTags.map((tag) => (
                <li key={tag.fieldValue}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="tag">
                    {tag.fieldValue}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      totalCount
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          timeToRead
          frontmatter {
            title
            date(formatString: "MMM D, YYYY")
          }
        }
      }
    }
  }
`
